@import "layout-vars";
@import "color-palette";
@import "vars";

.adblock {
    position: relative;
    max-width: 420px;
    min-height: 480px;
    max-height: 480px;

    &__placeholder {
        width: 100%;
        height: 100%;
        min-height: 480px;
        background-color: $color-bg-grey;
        border-radius: $border-radius-12;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text-grey;
        font-size: 13px;

        animation-name: ads-loading;
        animation-duration: 2s;
        animation-delay: .1s;
        animation-iteration-count: infinite;
    }
}

@keyframes ads-loading {
    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}