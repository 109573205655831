@import "layout-vars";
@import "color-palette";
@import "vars";
@import "breakpoints";

.ProductContactCard {
    width: $productContactCardWidth;
    max-height: 750px;
    left: 923px;
    top: 120px;

    background: $color-bg-white;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    padding: 20px 25px;

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-title {
            font-weight: $font-weight-bold;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: $color-primary-black;
            min-height: 50px;
            padding: 0;
            padding-right: 20px;
            margin: 0;
        }

        &-actions {
            display: flex;
            flex-direction: row;
            max-height: 30px;
            gap: 6px;
        }
    }

    &__favorite {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-icon {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            background-color: $color-bg-grey-light;
        }
    }
}

.CompareOffer {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__icon {
        width: 20px;
        height: 20px;
        fill: $color-bg-accent;

        &--active {
            fill: $color-bg-blue;
        }
    }

    &:hover {
        background-color: $color-bg-grey-light;
    }
}

.Price {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 10px;

    &__source {
        font-weight: $font-weight-bold;
        font-size: 26px;
        line-height: 32px;
        color: $color-primary-black;
    }

    &__usd {
        font-size: 14px;
        line-height: 16px;
        font-weight: $font-weight-semibold;
        color: $color-text-accent;
    }
}

.Tags {
    display: flex;
    justify-content: space-between;
    font-weight: $font-weight-medium;
    font-size: 16px;
    color: $color-primary-black;

    &__specs {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    &__metrics {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
}

.Divider {
    border-bottom: 0.5px solid $color-divider-border;
    width: 100%;
    height: 1px;
    margin: 16px 0;
}

.MobileOnly {
    @media (min-width: $container-max-width) {
        display: none;
    }
}

.DesktopOnly {
    @media (max-width: $container-max-width) {
        display: none;
    }
}

.DividerMobile {
    @extend .Divider;

    @media (min-width: $container-max-width) {
        display: none;
    }
}

.DividerDesktop {
    @extend .Divider;

    @media (max-width: $container-max-width) {
        display: none;
    }
}

.Props {
    .Prop {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        .PropValue {
            text-align: right;
            font-weight: $font-weight-semibold;
            font-size: 14px;
            line-height: 17px;
            color: $color-primary-black;
        }

        .PropLabel {
            font-weight: $font-weight-medium;
            font-size: 14px;
            line-height: 17px;
            color: $color-text-accent;
        }
    }
}

.Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    &_icon {
        width: 99px;
        height: 36px;
    }

    .UpdatedTime {
        font-weight: $font-weight-medium;
        font-size: 11px;
        line-height: 13px;
        color: $color-text-accent;
    }
}

.Buttons {
    >button:nth-child(1) {
        margin-bottom: 20px;
        margin-top: 25px;
    }
}

@media (max-width: $container-max-width) {
    .ProductContactCard {
        width: 100%;
        margin-bottom: 10px;

        .Content {
            display: flex;
            gap: 20px;

            .Info {
                flex-grow: 2;
            }

            .Buttons {
                flex-grow: 1;

                >button:nth-child(1) {
                    margin-top: 0;
                }

                >button:nth-child(1) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-product-contact) {
    .Content {
        flex-direction: column;
        gap: 0;

        .Info {
            flex-grow: 1;
        }

        .Buttons {
            flex-grow: 1;
        }
    }
}

.FooterLinkBtn {
    font-weight: $font-weight-semibold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-primary-blue;

    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;
}