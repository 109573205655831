@import "color-palette";
@import "vars";
@import "zIndexes";

.HoverGallery {
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;
    cursor: pointer;

    &__wrapper {
        position: relative;
        overflow: hidden;
        background: $color-bg-smoky-white;
        width: 100%;
        height: 100%;
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        color: transparent;
        object-fit: cover;

        &--hidden {
            display: none;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-bg-smoke-grey;
        z-index: $hoverGalleryOverlay;
    }

    &__more {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-text-white;
        font-weight: $font-weight-semibold;
        font-size: 16px;
        text-align: center;
        z-index: $hoverGalleryMore;
        pointer-events: none;
    }
}

.Indicator {
    position: absolute;
    bottom: 13px;
    display: flex;
    width: 100%;
    justify-content: center;

    &__bar {
        width: 37px;
        height: 3px;
        background: $color-bg-indicator;
        border-radius: $border-radius-50;

        &--active {
            background: $color-bg-black;
        }
    }
}