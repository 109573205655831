@import "vars";
@import "breakpoints";

.wrapper {
    position: relative;
    width: 274px;
    height: 482px;

    @media (max-width: $breakpoint-cards-two-columns) {
        width: auto;
    }
}

.wrapperCard {
    position: relative;
    width: 366px;
    height: 480px;
    margin-bottom: 19px;

    @media (max-width: $breakpoint-cards-two-columns) {
        margin-bottom: 10px;
    }

    @media (max-width: $breakpoint-mobile-banner) {
        height: 494px;
        padding-bottom: 0;
        box-shadow: none;
        width: fit-content;
    }

    @media (max-width: $breakpoint-mobile-md-sm) {
        width: fit-content;
    }
}

.wrapperHorizontal {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 6px 20px;
}

.img {
    border-radius: $border-radius-12;
    width: 100%;
    height: 100%;

    @media (max-width: $breakpoint-mobile-banner) {
        width: 100%;
        height: 100%;
    }
}
