@import "color-palette";
@import "vars";

.ProductProps {
    box-sizing: border-box;
    width: 100%;
    min-height: 150px;
    background: $color-bg-white;
    border-radius: $border-radius-8;
    padding: 25px;

    h3 {
        margin-top: 0;
        margin-bottom: 30px;
    }
}

.Props {
    display: flex;
    flex-wrap: wrap;

    .Prop {
        width: 176px;
        margin-bottom: 25px;

        .PropName {
            font-weight: $font-weight-medium;
            font-size: 14px;
            line-height: 17px;
            color: $color-text-accent;
            margin-bottom: 8px;
        }

        .PropValue {
            font-weight: $font-weight-semibold;
            font-size: 14px;
            line-height: 17px;
            color: $color-primary-black;
        }
    }
}