@import "mixins";
@import "color-palette";
@import "vars";

.DesktopMenu {
    @include flexList;
    align-items: center;
    margin-left: 30px;

    >div {
        margin-right: 10px;
    }
}

.ClosedItems {
    border: 0.5px solid $color-divider-border;
    border-radius: $border-radius-19;
    height: 38px;
    @include flexList;
    align-items: center;
    padding-left: 5px;

    >div {
        margin-right: 0;
    }

    >div:last-child {
        margin-right: 10px;
    }
}