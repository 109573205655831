@import "color-palette";

.Svg {
    &:hover,
    &.Active {
        rect.Highlight {
            fill: $color-bg-blue;
        }
    }
}

.HeartBtn {
    .HeartActive {
        display: block;
    }

    .HeartNotActive {
        display: none;
    }

    &:hover,
    &.Selected {
        .HeartActive {
            display: none;
        }

        .HeartNotActive {
            display: block;
        }
    }
}