@import "mixins";
@import "layout-vars";
@import "zIndexes";
@import "color-palette";
@import "vars";
@import "breakpoints";

.MobileMenu {
    margin-left: 36px;
    position: relative;
    height: 50px;
    border-bottom: 2px solid $color-border-blue;
    min-width: 68px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    >span {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
    }

    .ButtonContainer {
        display: flex;
        align-items: center;
        position: relative;
        top: 2px;

        span {
            padding-right: 4px;
            padding-left: 8px;
            font-weight: $font-weight-semibold;
            font-size: 13px;
            line-height: 16px;
            color: $color-primary-blue;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.Icon {
    position: absolute;
    right: -27px;
    top: 12px;
}

.ExpandedMenuCont {
    position: absolute;
    top: 60px;
    left: -7px;
    z-index: $mobileMenuZIndex;
    padding: 10px 0;
    background-color: $color-bg-white;
    border: 0.5px solid $color-border-light;
    box-shadow: $shadow-small-faint;
    border-radius: $border-radius-8;
}

.ExpandedMenu {
    @include list;

    .Li {
        height: 40px;
        width: 200px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: $color-bg-ghost-white;
        }

        &:hover {
            a {
                text-decoration: none;

                >span {
                    text-decoration: none;
                    color: $color-primary-blue;
                }

                >svg {
                    path {
                        fill: $color-bg-blue;
                    }
                }
            }
        }

        &.Active {
            background: $color-bg-ghost-white;

            a {
                color: $color-primary-blue;
            }

            svg {
                fill: $color-bg-blue;
            }
        }
    }
}

@media (max-width: $breakpoint-mobile-menu) {
    .MobileMenu {
        margin-left: 20px;
    }
}

@media (max-width: $breakpoint-mobile-md-sm) {
    .MobileMenu {
        margin-left: 0;
    }
}

.Disabled {
    border: 0.5px solid $color-divider-border;
    border-radius: $border-radius-19;
    margin: 0 6px;
    position: relative;

    .Li {
        height: 40px;
        width: 200px;
        padding-left: 13px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: none;
        }

        &:hover {
            a {
                text-decoration: none;

                >span {
                    text-decoration: none;
                    color: $color-text-lavender;
                }

                >svg {
                    path {
                        fill: $color-text-lavender;
                    }
                }
            }
        }

        &.Active {
            background: $color-bg-ghost-white;

            a {
                color: $color-primary-blue;
            }

            svg {
                fill: $color-bg-blue;
            }
        }
    }

    .Soon {
        position: absolute;
        font-weight: $font-weight-semibold;
        font-size: 12px;
        line-height: 15px;
        color: $color-text-white;
        background: $color-bg-red;
        border-radius: $border-radius-50;
        top: -10px;
        right: 0;
        padding: 3px 6px;
    }
}