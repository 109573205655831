@import "color-palette";
@import "vars";
@import "layout-vars";

.MyOffer {
    width: $productContactCardWidth;
    background: $color-bg-white;
    border-radius: $border-radius-8;
    border: 1px solid $color-border-grey;
    padding: 25px 25px 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__title {
        font-size: 20px;
        line-height: 24px;
        font-weight: $font-weight-bold;
        color: $color-primary-black;
        margin-bottom: 16px;
    }

    &__label {
        color: $color-text-accent;
    }

    &__props {
        font-size: 13px;
        line-height: 15px;
        font-weight: $font-weight-semibold;
    }

    &__actions {
        margin-top: 16px;
    }

    &__edit {
        display: inline-block;
        max-width: fit-content;
        font-size: 13px;
        line-height: 15px;
        color: $color-text-aqua;
        font-weight: $font-weight-semibold;
        border: 1px solid $color-border-blue;
        border-radius: $border-radius-6;
        padding: 12px;

        &:hover {
            text-decoration: none;
        }
    }
}