@import "color-palette";
@import "vars";

.TechnicalPause {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    background: $color-bg-white;
}

.Header {
    font-size: 18px;
    font-weight: $font-weight-medium;
    margin: 10px;
    text-align: center;
}

.P {
    font-size: 13px;
    margin: 0;
    font-weight: $font-weight-medium;
    text-align: center;
}
