@import "zIndexes";
@import "mixins";
@import "color-palette";
@import "vars";

.UserMenu {
    width: 172px;
    height: 160px;
    background: $color-bg-white;
    border: 0.5px solid $color-border-light;
    box-shadow: $shadow-small-faint;
    border-radius: 0 0 $border-radius-8 $border-radius-8;
    position: absolute;
    top: 43px;
    left: -5px;
    z-index: $layoutUserMenuZIndex;
    overflow: hidden;

    &__list {
        @include list;
    }

    &__list-item {
        @include noselect;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 16px;
        height: 40px;

        &:hover {
            background: $color-bg-ghost-white;
            cursor: pointer;
        }
    }

    &__link {
        font-weight: $font-weight-medium;
        font-size: 14px;
        line-height: 17px;
        color: $color-primary-black;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}