@import "color-palette";
@import "vars";
@import "breakpoints";

.Logo {
    font-weight: $font-weight-bold;
    font-size: 13px;
    line-height: 16px;

    &:hover {
        text-decoration: none;
    }

    >div {
        width: 205px;
        height: 36px;
        background: $color-bg-logo;
        border-radius: $border-radius-8;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text-black;
    }
}
@media (max-width: $breakpoint-mobile-md-sm) {
    .Logo {
        >div {
            width: calc(100% - 1em);
            margin-right: 1em;
        }
    }
}

.LogoCont {
    position: relative;
    background: none;
}

.NewYear {
    position: absolute;
    width: 100px;
    top: -40px;
    left: 52px;
    transform: rotate(40deg);
}

.IsHeader {
    top: -40px;
    left: -11px;
}