@import "color-palette";
@import "vars";

.PrimaryButton {
    width: 100%;
    background: $color-bg-blue;
    padding: 10px;
    border: none;
    color: $color-text-white;
    font-weight: $font-weight-semibold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .03em;
    border-radius: $border-radius-6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.LeftOriented {
        justify-content: space-between;
    }

    >svg {
        margin-right: 6px;
    }

    &.Large {
        background: $color-bg-blue;
        border-radius: $border-radius-10;
        height: 56px;
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: $color-text-white;
    }

    &.Primary {
        background: transparent;
        border: 1px solid $color-border-blue;
        color: $color-text-aqua;
        border-radius: $border-radius-10;
    }

    &.Light {
        background: transparent;
        border: 1px solid $color-border-blue;
        color: $color-text-aqua;
    }
}

.Loading {
    opacity: .5;
}

@-webkit-keyframes spin {
    0% {-webkit-transform: rotate(0deg);
    }

    100% {-webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {transform: rotate(0deg);
    }

    100% {transform: rotate(360deg);
    }
}

.Loader {
    width: 20px;
    height: 20px;
    border-radius: $border-radius-50-percent;
    border: 2px solid $color-active-white;
    border-top-color: $color-border-blue;
    animation: spin 1s linear infinite;
    margin-right: .5em;
}

.Content {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Arrow {
    position: relative;
    top: 2.5px;
}

.Icon {
    margin-right:10px;
    display: inline;
}