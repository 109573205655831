@import "mixins";
@import "color-palette";
@import "vars";
@import "breakpoints";

.CardsList {
    > ul {
        @include list;
    }
}

.Vertical {
    display: inline-grid;
    width:100%;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @media screen and (max-width: $breakpoint-catalog-three-columns) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $breakpoint-mobile-lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-mobile-md) {
        grid-template-columns: repeat(1, 1fr);
    }

    >li {
        width: 100%;
        min-width: 0;
    }
}

.PrimaryButton {
    margin: 40px auto 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    width: 220px;
    height: 56px;
    border: 1px solid $color-border-blue;
    border-radius: $border-radius-10;
    background-color: transparent;
    font-weight: $font-weight-semibold;
    font-size: 15px;
    line-height: 18px;
    color: $color-primary-blue;
}