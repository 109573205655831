@import "color-palette";
@import "vars";

.ProductDescription {
    box-sizing: border-box;
    width: 100%;
    min-height: 194px;
    background: $color-bg-white;
    border-radius: $border-radius-8;
    padding: 25px 25px 30px;

    h3 {
        margin-top: 0;
        font-weight: $font-weight-bold;
        font-size: 20px;
        line-height: 24px;
        color: $color-primary-black;
        margin-bottom: 20px;
    }

    a {
        font-weight: $font-weight-semibold;
        font-size: 13px;
        line-height: 16px;
        color: $color-primary-blue;
    }

    p {
        font-weight: $font-weight-medium;
        font-size: 15px;
        line-height: 18px;
        color: $color-text-brown-black;

        display: -webkit-box;
        display: -moz-box;
        display: -ms-box;
        display: flex;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        transition: max-height 0.8s;

        &.Expanded {
            -webkit-line-clamp: unset;
        }
    }
}