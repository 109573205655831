@import "color-palette";

.compare-toggle {
    width: 20px;
    height: 20px;
    fill: $color-bg-white;

    &__active {
        fill: $color-bg-blue;
    }
}