@import "mixins";
@import "vars";
@import "color-palette";

.breadcrumbs {
    margin: 20px auto;
    font-size: 13px;
    line-height: 16px;
    font-weight: $font-weight-medium;
    color: $color-text-accent;

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__arrow {
        margin: 0 10px;
    }
}
