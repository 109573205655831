@import "color-palette";
@import "vars";

.SimilarProductsList {
    h3 {
        margin: 0 0 30px;
        font-weight: $font-weight-semibold;
        font-size: 24px;
        line-height: 29px;
        color: $color-primary-black;
    }
}