@import "color-palette";
@import "zIndexes";
@import "breakpoints";
@import "vars";

.scroll-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: $color-bg-white;
    border-radius: 20%;
    box-shadow: $shadow-scroll-button;

    position: fixed;
    right: 40px;
    bottom: 40px;
    transition: all .3s ease;
    z-index: $scrollButton;
    opacity: 0;
    cursor: pointer;

    &__active {
        opacity: 1;
    }

    &__icon {
        fill: $color-bg-grey-dark;
        transition: fill 0.3s ease;

        &--rotate {
            transform: rotate(180deg);
        }
    }

    &:hover {
        background-color: $color-bg-grey-light;

        .scroll-button__icon {
            fill: $color-bg-black;
        }
    }
}

@media (max-width: $breakpoint-desktop-xs) {
    .scroll-button {
        display: none;
    }
}