@import "color-palette";
@import "vars";

.MainMenuButton {
    padding-left: 4px;
    padding-right: 5px;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 2px solid transparent;

    .ButtonContainer {
        display: flex;
        align-items: center;
        position: relative;
        top: 2px;

        svg {
            min-width: 24px;
        }

        span {
            padding-right: 4px;
            padding-left: 8px;
            font-weight: $font-weight-semibold;
            font-size: 13px;
            line-height: 16px;
            white-space: nowrap;
            color: $color-primary-black;
        }
    }

    &.Active {
        border-bottom: 2px solid $color-border-blue;

        .ButtonContainer {
            span {
                color: $color-text-aqua;
            }
        }
    }

    &:hover {
        .ButtonContainer {
            span {
                color: $color-text-aqua;
            }
        }
    }
}

.Disabled {
    &:hover {
        .ButtonContainer {
            span {
                color: $color-text-lavender;
            }
        }
    }

    .ButtonContainer {
        span {
            color: $color-text-lavender;
        }
    }
    color: $color-text-lavender;
}
