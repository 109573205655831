@import "color-palette";

.St1 {
    fill: $color-primary-blue;
}

.St2 {
    fill: $color-bg-grey;
}

.St0 {
    fill: rgba( $color-primary-blue, 0.6);
}

.Hovered {
    .St2 {
        fill: $color-primary-blue;
    }

    .St1 {
        fill: $color-bg-white
    }

    .St0 {
        fill: rgba($color-bg-white, 0.3)
    }
}