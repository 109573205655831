@import 'color-palette';
@import "vars";
@import 'zIndexes';
@import 'breakpoints';

.gallery {
    width: 100%;
    overflow: hidden;

    &__placeholder {
        display: flex;
        justify-content: center;
    }

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        min-height:300px;
        max-height: 500px;
        border-radius: $border-radius-8;
        overflow: hidden;
    }

    &__slide {
        position: relative;
        overflow: hidden;
        flex: 0 0 100%;
        min-width: 100%;
        max-width: 100%;
        height: 500px;
        min-height: auto;
    }

    &__wrapper {
        position: relative;
        max-width: 775px;
        width: 100%;
        max-height: 500px;
        height: 100%;
    }

    &__image {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: $border-radius-8;
    }

    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: $galleryButtonsZIndex;
        cursor: pointer;

        &--left {
            left: 16px;
        }

        &--right {
            right: 16px;
        }
    }

    @media (max-width: $breakpoint-mobile-md) {
        &__inner {
            height: 50vh;
        }
    }
}

.thumbnails {
    display: flex;
    gap: 15px;
    overflow: hidden;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 16px;

    &__item {
        flex: 0 0 140px;
        height: 100px;
        background: $color-bg-logo;
        border-radius: $border-radius-6;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
    }

    &__item:last-of-type::after {
        content: "Еще";
        position: absolute;
        max-width: 140px;
        width: 100%;
        height: 100px;
        background: $color-bg-smoke-grey;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text-white;
        font-size: 16px;
        line-height: 20px;
        font-weight: $font-weight-semibold;
        border-radius: $border-radius-6;
        pointer-events: none;
        cursor: pointer;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius-6;
        cursor: pointer;
    }

    @media (max-width: $breakpoint-tablet-xs) {
        .thumbnails__item:nth-child(n+5) {
            display: none;
        }
    }

    @media (max-width: $breakpoint-thumbnails-md) {
        .thumbnails__item:nth-child(n+4) {
            display: none;
        }
    }

    @media (max-width: $breakpoint-mobile-md-sm) {
        .thumbnails__item:nth-child(n+3) {
            display: none;
        }
    }
}