@import "color-palette";
@import "vars";
@import "zIndexes";
@import "breakpoints";

.cookie {
    position: fixed;
    bottom: 24px;
    right: 24px;
    max-width: 550px;
    background-color: $color-bg-white;
    border-radius: $border-radius-8;
    z-index: $consentCookie;
    box-shadow: $shadow-deep-dark;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;

    &__message {
        font-size: 13px;
        line-height: 15px;
        color: $color-primary-black;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__link {
        font-weight: $font-weight-semibold;
        color: $color-text-aqua;

        &:hover {
            text-decoration: none;
        }
    }

    &__button {
        font-size: 16px;
        line-height: 19px;
        font-weight: $font-weight-medium;
        color: $color-text-white;
        background-color: $color-bg-blue;
        border-radius: $border-radius-8;
        border: none;
        padding: 12px;
        cursor: pointer;
    }

    @media (max-width: $breakpoint-mobile-md-lg) {
        right: 0;
        bottom: 0;
        width: 100%;
        border-radius: $border-radius-8 $border-radius-8 0 0;
    }
}