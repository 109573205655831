@import "mixins";
@import "layout-vars";
@import "color-palette";
@import "breakpoints";

.ProductScreen {
    @include container;
    padding-bottom: 40px;

    &__inner {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;

        @media (max-width: $breakpoint-cards-two-columns) {
            flex-direction: column-reverse;
            display: flex;
            gap: 16px;
        }

        @media (max-width: $breakpoint-mobile-md-sm) {
            display: block;
        }
    }

    &__column {
        width: calc(100% - $productContactCardWidth);
        @media (max-width: $breakpoint-cards-two-columns) {
            width: 100%;
        }
    }

    &__contact {
        position: -webkit-sticky;
        position: sticky;
        top: 10px;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: $breakpoint-cards-two-columns) {
            top: 0;
            position: relative;
            width: 100%;
            flex-direction: row;
            gap: 16px;
        }

        @media (max-width: $breakpoint-mobile-md-sm) {
            display: block;
        }
    }

    &__chart {
        margin-bottom: 24px;
        margin-top: 24px;
    }

    &__error {
        display: flex;
        flex-direction: column;

        &--title {
            font-size: 20px;
        }

        &--text {
            margin: 6px 0;
        }

        &--link {
            color: $color-primary-blue;
        }
    }
}