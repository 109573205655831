@import "color-palette";
@import "vars";

.seller {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    padding: 16px 0;
    border-top: 0.5px solid $color-divider-border;
    border-bottom: 0.5px solid $color-divider-border;

    &__inner {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__status {
        display: flex;
        align-items: center;
        gap: 6px;
        width: fit-content;
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-medium;
        background-color: $color-bg-blue-light;
        border-radius: $border-radius-6;
        padding: 4.5px 6px;
        margin-top: 10px;
    }

    &__avatar {
        width: 46px;
        height: 46px;
        border-radius: $border-radius-50-percent;
        margin-right: 16px;
    }

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-size: 14px;
        line-height: 17px;
        font-weight: $font-weight-semibold;
        color: $color-primary-black;
    }

    &__type {
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-medium;
        color: $color-text-accent;
    }
}