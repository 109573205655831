@import "color-palette";
@import "vars";

.product-location {
    background: $color-bg-white;
    padding: 25px;
    border-radius: $border-radius-8;
    margin-bottom: 20px;

    &__title {
        font-weight: $font-weight-bold;
        font-size: 20px;
        line-height: 24px;
        color: $color-primary-black;
        margin: 0 0 23px;
    }

    &__name {
        display: flex;
        gap: 9px;
        color: $color-primary-black;
        font-weight: $font-weight-semibold;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 16px;
    }

    &__map {
        width: 100%;
        height: 400px;
    }
}