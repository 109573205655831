@import "color-palette";
@import "vars";

.LocationSnippet {
    font-weight: $font-weight-medium;
    font-size: 13px;
    line-height: 16px;
    color: $color-text-accent;
    display: flex;
    align-items: center;

    >svg {
        margin-right: 8px;
    }
}